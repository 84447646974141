import React, { useState } from "react"

import grid from "../../scss/flexboxgrid.module.scss"
import view from "./directions.module.scss"

export default function Directions(props) {
  const { dealerAddress, dealerName, serviceHours, salesHours } = props.data

  const [origin, setOrigin] = useState('')

  const getDirections = () => {
    const baseURL = "https://www.google.com/maps/dir/"
    const escapedOrigin = String(origin).replaceAll(' ', '+')
    const destiny = String(dealerAddress).replaceAll(' ', '+')
    window.open(`${baseURL}${escapedOrigin}/${destiny}`)
  }

  const renderHours = (hours) => (
    <div className={view["hour-wrap"]}>
      {hours.map((el) => (
        <div className={view["hour-cont"]}>
          <span>{el.day}</span>
          <span>{el.time}</span>
        </div>
      ))}
    </div>
  )

  const renderSearch = () => (
    <div className={view["search-cont"]}>
      <input id="search-dir-input" className={view["search-dir"]} placeholder={"Enter starting address"}
        onChange={(e) => setOrigin(e.target.value)} />
      <button className={view["search-btn"]} onClick={getDirections}>
        <p style={{ position: "relative", top: "-6px"}}>
          &#x2315;
        </p>
      </button>
    </div>
  )

  return (
    <div className={view["directions"]}>
      <div className={view["dir-left"]}>
        <div className={view["left-wrap"]}>
          <h2 className={view["address"]}>{dealerAddress}</h2>
          <label htmlFor="search-dir-input" >{`Get directions to ${dealerName}`}</label>
          {renderSearch()}

          <div className={view["dir-cols"]}>
            <div className={grid["col-lg-6"]}>
              <h2 className={view["col-head"]}>
                Sales
            </h2>
              {renderHours(salesHours)}
            </div>
            <div className={grid["col-lg-6"]}>
              <h2 id="hours-direction-service-parts" className={view["col-head"]}>
                Service & Parts
            </h2>
              {renderHours(serviceHours)}
            </div>
          </div>
        </div>
      </div >
      <div className={view["dir-right"]}>
        {
          dealerAddress.length > 0 && (
            <iframe
              src={`https://www.google.com/maps?q=${escape(dealerAddress)}&hl=en-US&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp&output=embed`}
              style={{ border: 0 }}
              allowfullscreen=""
              loading={"lazy"} 
            />
          )
        }
      </div>
    </div>
  )
}

Directions.defaultProps = {
  data: {
    dealerAddress: "7321 Firestone Blvd, Downey CA 90241",
    dealerName: "Nissan Downey",
    salesHours: [
      { day: "Monday", time: "9:00 AM - 9:00 PM" },
      { day: "Tuesday", time: "9:00 AM - 9:00 PM" },
      { day: "Wednesday", time: "9:00 AM - 9:00 PM" },
      { day: "Thusrday", time: "9:00 AM - 9:00 PM" },
      { day: "Friday", time: "9:00 AM - 9:00 PM" },
      { day: "Saturday", time: "9:00 AM - 9:00 PM" },
      { day: "Sunday", time: "9:00 AM - 9:00 PM" },
    ],
    serviceHours: [
      { day: "Monday", time: "9:00 AM - 9:00 PM" },
      { day: "Tuesday", time: "9:00 AM - 9:00 PM" },
      { day: "Wednesday", time: "9:00 AM - 9:00 PM" },
      { day: "Thusrday", time: "9:00 AM - 9:00 PM" },
      { day: "Friday", time: "9:00 AM - 9:00 PM" },
      { day: "Saturday", time: "9:00 AM - 9:00 PM" },
      { day: "Sunday", time: "9:00 AM - 9:00 PM" },
    ]
  }
}